import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class Community extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Community"}
          titleDesc={
            " Fuse Classroom's Community brings faculty members and students together to discuss and share their knowledge in a centralized space."
          }
          titleKeywords={
            "fuse classroom, online community, community forum, online learning community, online learning community platform, student clubs, online community platforms"
          }
          fbDes={" Fuse Classroom's Community provides an access to a centralized space for group discussions."}
          twitDesc={" Fuse Classroom's Community provides an access to a centralized space for group discussions."}
          seoLink={"platform/community/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Community</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Extend your school’s community online. Fuse Classroom helps create a safe online community for
                    students and other faculty.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Community_Top_Request_Demo")}
                      state={{ page: "Community" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img src="/images/platform/community/community.webp" alt="Community" width="640" height="auto" />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Community */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="my-2 text-center h1">Community</div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Community-Forum")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/community.svg" alt="Community Forum" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Community Forum</Card.Title>
                      <div className="small">
                        Provides centralized space to discuss upcoming events, meetings, or any other topics.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Groups")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/groups.svg" alt="Groups" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Groups</Card.Title>
                      <div className="small">Bring students, instructors, and administrators together.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Chat")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/chats.svg" alt="Chat" className="img-fluid" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Chat</Card.Title>
                      <div className="small">
                        Stay in touch with everyone in your community through easy instant messaging.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Student-Clubs")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/students-clubs.svg"
                          alt="Student Clubs"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Student Clubs</Card.Title>
                      <div className="small">
                        Provides easy access to a student’s clubs, including club-specific message boards, video
                        conferencing, and more.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Alumni")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/alumni.svg" alt="Alumni" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Alumni</Card.Title>
                      <div className="small">
                        Build and strengthen relationships between current students and alumni.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Events")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/events.svg" alt="Events" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Events</Card.Title>
                      <div className="small">
                        Stay updated on upcoming events, socials, career fairs, exams, and more.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Community End */}

        {/* Community Forum*/}
        <section id="Community-Forum" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/community/forum.webp"
                  alt="Community Forum"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/community.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Community Forum</h2>
                  <div className="h5 text-black font-weight-normal">
                    On the community page is a forum that gives all platform users access to a centralized place to
                    discuss topics such as upcoming events, meetings, among others. The forum is moderated by an AI
                    system so no hate-speech, bullying, or inappropriate topics are discussed.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* fuse classroom Community end */}

        {/* Groups */}
        <section id="Groups" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img src="/images/platform/community/groups.webp" alt="Groups" className="img-fluid" />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/groups.svg" alt="Groups" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Groups</h2>
                  <div className="h5 text-black font-weight-normal">
                    Groups can be created by any user to bring together students, instructors, and administrators.
                    Groups include features needed to facilitate activities, including group chats, file-sharing,
                    calendars, and more.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Groups end */}

        {/* Chat */}
        <section id="Chat" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/community/chat.webp"
                  alt="Chat"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/chats.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Chat</h2>
                  <div className="h5 text-black font-weight-normal">
                    Similar to Facebook Messenger and WhatsApp, the Fuse Classroom Chat feature allows students to stay
                    in touch with one another. The feature is automatically moderated by an AI system so any hate
                    speech, verbal abuse, or other issues are immediately flagged and administrators are notified.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Chat end) */}

        {/* Student Clubs */}
        <section className="py-5 bg-80 left" id="Student-Clubs">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/community/students-club.webp"
                  alt="Student Clubs"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/students-clubs.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Student Clubs</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Student Clubs feature gives administrators and students a digital space to store club
                    activities, files, and discussions. Clubs can also use the video communication feature to host
                    meetings if need be.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Student Clubs end */}

        {/* Alumni */}
        <section id="Alumni" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/community/alumni.webp"
                  alt="Alumni"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/alumni.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Alumni</h2>
                  <div className="h5 text-black font-weight-normal">
                    To create an everlasting and ever-growing community, it is important to build strong relationships
                    between current students and alumni. The Alumni feature allows both administrators and students to
                    stay in touch with alumni.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* ALumni end) */}

        {/* Events */}
        <section className="py-5 bg-80 left" id="Events">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/community/events.webp"
                  alt="Events"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/events.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Events</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Event feature compiles all upcoming events, socials, career fairs, and board exams, in one
                    place. Events can be created by administrators and instructors. Students receive immediate
                    notifications so no one misses an important event.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Events end */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("Community_Bottom_Request_Demo")}
                    state={{ page: "Community" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default Community
